<template>
  <b-modal
    id="file-upload-modal"
    v-model="visible"
    title="Bill Upload"
    size="lg"
    ok-only
    :ok-title="busyUploading ? 'Uploading' : 'Upload'"
    :ok-disabled="busyUploading || filesToUpload.length < 1"
    @ok.prevent="$refs.fileUpload.uploadFiles()"
    :hide-header-close="busyUploading"
    :hide-footer="uploadComplete"
    @hide="resetModal()"
  >
    <div v-if="uploadComplete">
      <h5 class="text-center pb-2">
        Your bills were uploaded successfully. Here's what's coming next:
      </h5>
      <DotCards
        class="pt-lg-3"
        text1="We will carefully review your bills and will let you know if we're missing anything."
        text2="We'll compare your business' costs against hundreds of suppliers to find a cheaper deal."
        text3="We will send you an email with a link to your updated personalised cost savings report."
      />
    </div>
    <div v-else>
      <p>
        Upload one or more of your latest
        {{ deal ? deal.supplierName + " " : "" }}
        bills. Make sure the bill contains all pages.
      </p>
      <FileUpload
        ref="fileUpload"
        :rename="!deal || !deal.supplierName"
        :supplier-name="deal ? deal.supplierName : null"
        accept="application/pdf, image/png, image/jpeg"
        @upload-files="uploadFiles"
        @update="filesToUpload = $event"
      />
      <b-alert :show="errorMessage != null" variant="danger" class="mt-3 mb-0">{{
        errorMessage
      }}</b-alert>
      <b-overlay :show="busyUploading" no-wrap spinner-variant="primary" />
    </div>
  </b-modal>
</template>

<script>
import FileUpload from "@/components/FileUpload";
import ApiHelper from "@/helper/apihelper";
import DotCards from "@/components/DotCards";

export default {
  name: "FileUploadModal",
  components: { FileUpload, DotCards },
  data() {
    return {
      visible: false,
      busyUploading: false,
      uploadComplete: false,
      organisationId: null,
      deal: {},
      filesToUpload: [],
      errorMessage: null
    };
  },
  methods: {
    show(orgId, deal) {
      this.organisationId = orgId;
      this.deal = deal;
      this.visible = true;
    },

    async uploadFiles(formData) {
      this.busyUploading = true;
      formData.append("organisationId", this.organisationId);

      const client = await ApiHelper.http();
      await client
        .post(`${ApiHelper.endPoint()}organisations/files`, formData)
        .then(response => {
          this.$refs.fileUpload.clearSelected();
          this.uploadComplete = true;
        })
        .catch(e => {
          this.errorMessage = "There was a problem saving the file(s): " + e.response.data.message;
        })
        .finally(() => {
          this.busyUploading = false;
        });
    },

    resetModal() {
      this.uploadComplete = false;
      this.errorMessage = null;
    }
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
#file-upload-modal {
  .file-upload {
    .upload-btn {
      display: none;
    }
  }
}
</style>

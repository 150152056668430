<template>
  <div
    class="gradient-wrapper"
    v-bind:style="{
      padding: thickness,
      backgroundColor: colorA,
      backgroundImage: 'linear-gradient(' + colorA + ',' + colorB + ') '
    }"
    @click="$emit('click')"
  >
    <div id="maincircle" v-bind:style="{ width: size, height: size }">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";
.gradient-wrapper {
  padding: 10px;
  border-radius: 50%;
  display: inline-block;

  #maincircle {
    display: table-cell;
    text-align: center;
    vertical-align: middle;

    border-radius: 50%; /* may require vendor prefixes */
    background: white;
  }
}
</style>

<script>
export default {
  name: "GradientCircle",
  props: {
    size: {
      type: String,
      default: "200px"
    },
    thickness: {
      type: String,
      default: "10px"
    },
    colorA: {
      type: String,
      default: "#F40C8F"
    },
    colorB: {
      type: String,
      default: "#1993EA"
    }
  },
  methods: {}
};
</script>
<template>
  <b-form class="file-upload" @submit.prevent="uploadFiles" ref="form">
    <label for="file-input" class="file-drop w-100 px-3 py-2 rounded text-center">
      <font-awesome-icon :icon="['fal', 'arrow-circle-up']" class="mr-2" />
      Drag and Drop files here or <span class="text-primary underline pointer">choose file</span>
      <b-form-file
        v-model="selectedFiles"
        class="mb-2 pointer"
        id="file-input"
        multiple
        :accept="accept"
        @input="onFileInput"
      ></b-form-file>
    </label>
    <div class="d-flex w-100 px-1 file-limits">
      <span class="text-muted w-50">Files Supported: {{ supportedFilesDesc() }}</span>
      <span class="text-muted w-50 text-right">Maximum Size: {{ maxFileSize / 100000 }}MB</span>
    </div>
    <div v-if="filesToUpload && filesToUpload.length > 0" class="mt-3">
      <b-row
        v-for="file in filesToUpload"
        :key="file.name"
        align-v="center"
        class="my-2 pt-2 px-md-3 file-row"
      >
        <b-col cols="10" md="11">
          <b-row align-v="center">
            <b-col cols="12" :md="rename ? 7 : 12" class="file-name d-flex align-items-center">
              <font-awesome-icon
                :icon="['fal', 'file-check']"
                :class="
                  'file-icon mr-2 mr-md-3 ' + (file.tooLarge ? 'text-danger' : 'text-success')
                "
              />
              <b class="m-0 text-nowrap">
                {{ file.name }}
                <span v-if="file.tooLarge" class="small text-danger"
                  ><br />File size exceeds 25MB</span
                >
              </b>
            </b-col>
            <b-col cols="12" md="5" v-if="rename" class="invoice-name mt-2 mt-md-0">
              <b-input
                v-model="file.invoiceName"
                class="reducer-input"
                size="sm"
                required
                placeholder="Enter supplier, e.g. British Gas"
              />
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="2" md="1" class="delete-col">
          <b-button class="delete-button p-0 " pill @click="deleteRow(file)"
            ><font-awesome-icon :icon="['fal', 'plus']" class="rotate-icon"
          /></b-button>
        </b-col>
      </b-row>
      <b-button
        v-if="!busyUploading"
        variant="primary"
        class="upload-btn float-right mt-2 mb-5"
        :disabled="filesToUpload.length == 0"
        type="submit"
        >Upload</b-button
      >
      <b-button v-else variant="primary" class="upload-btn float-right mt-2 mb-5" disabled>
        <Busy :size="1.5" :margin="0" />
      </b-button>
    </div>
  </b-form>
</template>

<script>
import Busy from "@/components/Busy";

export default {
  name: "FileUpload",
  components: { Busy },
  props: {
    busyUploading: Boolean,
    rename: Boolean,
    accept: String,
    supplierName: String
  },
  data() {
    return {
      selectedFiles: [],
      filesToUpload: [],
      maxFileSize: 2500000
    };
  },
  computed: {
    validFiles() {
      return this.filesToUpload.filter(f => !f.tooLarge);
    }
  },
  methods: {
    onFileInput(selectedFiles) {
      if (selectedFiles && selectedFiles.length) {
        selectedFiles.forEach(selectedFile => {
          if (!this.filesToUpload.some(f => f.name == selectedFile.name)) {
            console.log(selectedFile.size);
            if (selectedFile.size > this.maxFileSize) {
              selectedFile.tooLarge = true;
            }
            if (this.supplierName) {
              selectedFile.invoiceName = this.supplierName;
            }
            this.filesToUpload.push(selectedFile);
          }
        });
      }
      this.$emit("update", this.validFiles);
    },

    uploadFiles() {
      if (this.$refs.form.checkValidity()) {
        let formData = new FormData();
        this.validFiles.forEach(f => formData.append("file", f));

        let names = "";
        this.validFiles.forEach(f => (names += f.name + "#" + f.invoiceName + ";"));
        formData.append("invoiceNames", names);

        this.$emit("upload-files", formData);
      } else {
        this.$refs.form.reportValidity();
      }
    },

    deleteRow(file) {
      this.selectedFiles = this.selectedFiles.filter(f => f.name != file.name);
      this.filesToUpload = this.filesToUpload.filter(f => f.name != file.name);
      this.$emit("update", this.filesToUpload);
    },

    clearSelected() {
      this.selectedFiles = [];
      this.filesToUpload = [];
    },

    supportedFilesDesc() {
      if (this.accept) {
        return this.accept
          .replaceAll("image/", "")
          .replaceAll("application/", "")
          .toUpperCase();
      } else {
        return "any";
      }
    }
  }
};
</script>

<style lang="scss">
.file-upload {
  @import "@/styles/common.scss";
  .file-row {
    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey-lighter3;
      padding-bottom: 0.5rem;
    }

    .file-name {
      b {
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .file-icon {
        font-size: 1.5rem;
      }
    }

    .delete-col {
      border-left: 1px solid $color-grey-lighter3;
      .delete-button {
        background-color: $color-grey-lighter4;
        border-color: transparent;
        color: $color-font-para;
        width: 1.5rem;
        height: 1.5rem;
        svg {
          transform: rotate(45deg);
        }
        &:hover {
          background-color: $color-red-light2;
          color: white;
        }
      }
    }
  }
  .file-limits {
    font-size: 0.89rem !important;
  }
  .file-drop {
    border: 3px dashed $color-grey-lighter3;
    color: $color-font-headings;
    font-size: 1.1rem;
    position: relative;
  }
  .custom-file {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
  }
}
</style>
